<template>
  <footer class="bg-gray-200 py-6 mt-8">
    <div class="container mx-auto px-4 text-center text-gray-600">
      <p class="text-sm">
        &copy; 2024 English Proficiency Test. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
